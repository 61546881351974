import {ErrorBoundary} from "react-error-boundary";
import CharacterApp from "./components/CharacterApp/CharacterApp";
import {BrowserRouter, Routes, Route, useParams} from "react-router-dom";
import {CharacterTabsProvider} from "contexts/CharacterTabs";
import {isDesktop, PUBLIC_URL} from "helpers/app";
import {useEffect, useMemo} from "react";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import "styles/general/_toastify.scss";
import CloseIcon from "icons/CloseIcon";
import useBEM from "hooks/useBEM";
import IconButton from "components/interface/IconButton";
import InstallPrompt from "components/interface/InstallPrompt";
import RotateOverlay from "components/RotateOverlay";
import {LoadingStateProvider} from "hooks/useAppLoading";
import NotFoundError from "components/NotFoundError";
import RuntimeError from "components/RuntimeError";
import TagManager from "react-gtm-module/dist/TagManager";

function ToastCloseButton({closeToast}) {
  const classes = useBEM('Toastify');
  return <IconButton className={classes('close-button')} onClick={closeToast}>
    <CloseIcon/>
  </IconButton>
}

function CharacterTabController() {
  const {tab, subTab} = useParams();
  const tabValue = useMemo(() => {
    const tabValue = [];
    if (tab && tab !== 'home') {
      tabValue.push(tab);
      if (subTab) {
        tabValue.push(subTab);
      }
    }
    return tabValue.map(item => item.replace('-', '_'));
  }, [tab, subTab]);

  return <CharacterTabsProvider value={tabValue.length > 0 ? tabValue.join('__') : null}>
    <LoadingStateProvider>
      <CharacterApp/>
    </LoadingStateProvider>
  </CharacterTabsProvider>
}

function App() {
  useEffect(() => {
    if (isDesktop) {
      toast('For the best experience, we recommend using a mobile device to play the game.', {
        toastId: 'useMobile',
      });
    }

    if (process.env.REACT_APP_GTM_ID) {
      const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_ID,
      };
      TagManager.initialize(tagManagerArgs);
    }
  }, []);

  return (<ErrorBoundary fallback={<RuntimeError/>}>
    <RotateOverlay/>
    <ToastContainer className={'toast'} closeButton={<ToastCloseButton/>} autoClose={false}/>
    <InstallPrompt/>
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route path="/" element={<CharacterTabController/>}/>
        <Route path="/home" element={<CharacterTabController/>}/>
        <Route path="/tabs/:tab" element={<CharacterTabController/>}/>
        <Route path="/tabs/:tab/:subTab" element={<CharacterTabController/>}/>
        <Route path="/not-found" element={<NotFoundError/>}/>
        <Route path="*" element={<NotFoundError/>}/>
      </Routes>
    </BrowserRouter>
  </ErrorBoundary>);
}

export default App;
