export default function RestartIcon({size = 70}) {
  return <svg width={size} height={size} viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M31.65 47.9235C28.2167 46.9466 25.4167 45.0351 23.25 42.1888C21.0833 39.3426 20 36.0669 20 32.3617C20 30.4417 20.3167 28.6144 20.95 26.8797C21.5833 25.145 22.4833 23.5534 23.65 22.105C24.0167 21.7008 24.4667 21.4903 25 21.4735C25.5333 21.4566 26.0167 21.6672 26.45 22.105C26.8167 22.4756 27.0083 22.9303 27.025 23.4692C27.0417 24.0082 26.8667 24.5134 26.5 24.985C25.7 26.0292 25.0833 27.1744 24.65 28.4207C24.2167 29.667 24 30.9806 24 32.3617C24 35.09 24.7917 37.5237 26.375 39.6626C27.9583 41.8015 30 43.2583 32.5 44.033C32.9333 44.1677 33.2917 44.4204 33.575 44.7909C33.8583 45.1614 34 45.5656 34 46.0035C34 46.6772 33.7667 47.2077 33.3 47.595C32.8333 47.9824 32.2833 48.0919 31.65 47.9235ZM40.35 47.9235C39.7167 48.0919 39.1667 47.974 38.7 47.5698C38.2333 47.1656 38 46.6266 38 45.953C38 45.5488 38.1417 45.1614 38.425 44.7909C38.7083 44.4204 39.0667 44.1677 39.5 44.033C42 43.2246 44.0417 41.7594 45.625 39.6373C47.2083 37.5152 48 35.09 48 32.3617C48 28.9933 46.8333 26.1302 44.5 23.7724C42.1667 21.4145 39.3333 20.2356 36 20.2356H35.85L36.65 21.044C37.0167 21.4145 37.2 21.8861 37.2 22.4587C37.2 23.0313 37.0167 23.5029 36.65 23.8734C36.2833 24.244 35.8167 24.4292 35.25 24.4292C34.6833 24.4292 34.2167 24.244 33.85 23.8734L29.65 19.6293C29.45 19.4272 29.3083 19.2083 29.225 18.9725C29.1417 18.7367 29.1 18.4841 29.1 18.2146C29.1 17.9451 29.1417 17.6925 29.225 17.4567C29.3083 17.2209 29.45 17.002 29.65 16.7999L33.85 12.5558C34.2167 12.1853 34.6833 12 35.25 12C35.8167 12 36.2833 12.1853 36.65 12.5558C37.0167 12.9263 37.2 13.3979 37.2 13.9705C37.2 14.5431 37.0167 15.0147 36.65 15.3852L35.85 16.1936H36C40.4667 16.1936 44.25 17.7599 47.35 20.8924C50.45 24.025 52 27.8481 52 32.3617C52 36.0332 50.9167 39.3005 48.75 42.1636C46.5833 45.0267 43.7833 46.9466 40.35 47.9235Z"
      fill="#696969"/>
    <path
      d="M30.65 46.9235C27.2167 45.9466 24.4167 44.0351 22.25 41.1888C20.0833 38.3426 19 35.0669 19 31.3617C19 29.4417 19.3167 27.6144 19.95 25.8797C20.5833 24.145 21.4833 22.5534 22.65 21.105C23.0167 20.7008 23.4667 20.4903 24 20.4735C24.5333 20.4566 25.0167 20.6672 25.45 21.105C25.8167 21.4756 26.0083 21.9303 26.025 22.4692C26.0417 23.0082 25.8667 23.5134 25.5 23.985C24.7 25.0292 24.0833 26.1744 23.65 27.4207C23.2167 28.667 23 29.9806 23 31.3617C23 34.09 23.7917 36.5237 25.375 38.6626C26.9583 40.8015 29 42.2583 31.5 43.033C31.9333 43.1677 32.2917 43.4204 32.575 43.7909C32.8583 44.1614 33 44.5656 33 45.0035C33 45.6772 32.7667 46.2077 32.3 46.595C31.8333 46.9824 31.2833 47.0919 30.65 46.9235ZM39.35 46.9235C38.7167 47.0919 38.1667 46.974 37.7 46.5698C37.2333 46.1656 37 45.6266 37 44.953C37 44.5488 37.1417 44.1614 37.425 43.7909C37.7083 43.4204 38.0667 43.1677 38.5 43.033C41 42.2246 43.0417 40.7594 44.625 38.6373C46.2083 36.5152 47 34.09 47 31.3617C47 27.9933 45.8333 25.1302 43.5 22.7724C41.1667 20.4145 38.3333 19.2356 35 19.2356H34.85L35.65 20.044C36.0167 20.4145 36.2 20.8861 36.2 21.4587C36.2 22.0313 36.0167 22.5029 35.65 22.8734C35.2833 23.244 34.8167 23.4292 34.25 23.4292C33.6833 23.4292 33.2167 23.244 32.85 22.8734L28.65 18.6293C28.45 18.4272 28.3083 18.2083 28.225 17.9725C28.1417 17.7367 28.1 17.4841 28.1 17.2146C28.1 16.9451 28.1417 16.6925 28.225 16.4567C28.3083 16.2209 28.45 16.002 28.65 15.7999L32.85 11.5558C33.2167 11.1853 33.6833 11 34.25 11C34.8167 11 35.2833 11.1853 35.65 11.5558C36.0167 11.9263 36.2 12.3979 36.2 12.9705C36.2 13.5431 36.0167 14.0147 35.65 14.3852L34.85 15.1936H35C39.4667 15.1936 43.25 16.7599 46.35 19.8924C49.45 23.025 51 26.8481 51 31.3617C51 35.0332 49.9167 38.3005 47.75 41.1636C45.5833 44.0267 42.7833 45.9466 39.35 46.9235Z"
      fill="white"/>
  </svg>
}
