import "styles/components/_modal.scss";
import {Modal as CoreModal} from "@mantine/core";
import useBEM from "hooks/useBEM";
import ScrollArea from "components/interface/ScrollArea";

export default function Modal(params) {
  const classes = useBEM('modal');

  return <CoreModal classNames={{
    root: classes(),
    inner: classes('inner'),
    content: classes('content'),
    header: classes('header'),
    overlay: classes('overlay'),
    title: classes('title'),
    body: classes('body'),
    close: classes('close'),
  }} scrollAreaComponent={ScrollArea} withCloseButton={false} {...params}/>
}
