import RestartIcon from "icons/RestartIcon";
import IconButton from "components/interface/IconButton";
import {useCallback} from "react";
import useBEM from "hooks/useBEM";
import {useCharacterParams} from "contexts/CharacterParams";
import {useDisclosure} from "@mantine/hooks";
import Modal from "components/interface/Modal";
import Button from "components/interface/Button";

export default function RestartButton() {
  const classes = useBEM('restart-button');
  const [opened, {open, close}] = useDisclosure(false);
  const {isDefault, reset} = useCharacterParams();

  const handleClick = useCallback(() => {
    open();
  }, [open]);

  const handleReset = useCallback(() => {
    reset();
    close();
  }, [reset, close]);

  return <>
    <IconButton disabled={isDefault} variant={'game'} className={classes()} onClick={handleClick}>
      <RestartIcon/>
    </IconButton>
    <Modal title={'Reset All Styles?'} opened={opened} onClose={close}>
      <p>
        Are you sure you want to reset your character? All the fun styles you picked will go away, and you'll start from
        the beginning.
      </p>
      <div className={'modal__actions'}>
        <Button variant={'secondary'} onClick={handleReset}>Yes, Reset All</Button>
        <Button variant={'tertiary'} onClick={close}>No, Go Back</Button>
      </div>
    </Modal>
  </>
}
