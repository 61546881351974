import "styles/components/_button.scss";
import {ActionIcon} from "@mantine/core";
import useBEM from "hooks/useBEM";

export default function IconButton({variant, ...props}) {
  const classes = useBEM('button');
  const modifiers = {
    'icon': true,
  };
  if (variant) {
    modifiers[variant] = true;
  }

  return <ActionIcon classNames={{
    root: classes(modifiers),
    icon: classes('icon'),
  }} {...props}/>
}
