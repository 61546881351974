import React from "react";
import ReactDOM from "react-dom/client";
import './styles/global.scss';
import App from "./App";
import {HeadlessMantineProvider} from "@mantine/core";
import * as serviceWorkerRegistration from "serviceWorkerRegistration";
import {toast} from "react-toastify";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <HeadlessMantineProvider>
      <App/>
    </HeadlessMantineProvider>
  </React.StrictMode>
);

const handleUpdate = (registration) => {
  toast.warning('New application version is available. Close this message to update.', {
    autoClose: false,
    onClose: () => {
      if (registration.waiting) {
        // let waiting Service Worker know it should became active
        registration.waiting.postMessage({type: 'SKIP_WAITING'});
      }
    }
  });
}

serviceWorkerRegistration.register({
  onRegister: (registration) => {
    if (registration.waiting) {
      handleUpdate(registration);
    }
  },
  onUpdate: (registration) => {
    handleUpdate(registration);
  }
});
