import axios from 'axios'

let token = JSON.parse(localStorage.getItem('token'));
const abortController = new AbortController();
const client = axios.create({
  'baseURL': process.env.REACT_APP_API_BASE_URL,
  'mode': 'cors',
  'headers': {
    'Accept': 'application/vnd.api+json',
    'Content-Type': 'application/json',
  },
  'signal': abortController.signal,
});

const getClient = () => {
  return client;
}

const abort = () => {
  abortController.abort();
}

const getToken = async () => {
  if (!token || token.expires < Date.now() / 1000) {
    const response = await getClient().request({
      url: `/token`,
      method: 'POST',
      data: {},
    })
    token = response.data;
    localStorage.setItem('token', JSON.stringify(token));
  }

  return token;
}

const apiService = {
  getClient,
  abort,
  getToken,
}

export default apiService
